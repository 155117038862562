import React, { forwardRef, useId } from 'react';
import {
  CSSObject,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  SystemStyleObject,
} from '@chakra-ui/react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { FormCommonProps, formForwardProps } from '../../utils/forwardProps';
import { FormErrorIcon } from '../Icon';

/**
 * @package
 */
export interface BaseFormInputProps extends InputProps, FormCommonProps {
  name: string;
  ref?: React.Ref<HTMLInputElement>;
  /**
   * Add icons/buttons at the left of the `Input` inside the `InputGroup`
   * @see https://chakra-ui.com/docs/form/input#left-and-right-addons
   * @see https://chakra-ui.com/docs/form/input#add-elements-inside-input
   */
  inputLeft?: React.ReactNode;
  /**
   * By default icons have `pointerEvents="none"`
   */
  inputLeftClickable?: boolean;
  /**
   * Add icons/buttons at the right of the `Input` inside the `InputGroup`
   * @see https://chakra-ui.com/docs/form/input#left-and-right-addons
   * @see https://chakra-ui.com/docs/form/input#add-elements-inside-input
   */
  inputRight?: React.ReactNode;
  /**
   * By default icons have `pointerEvents="none"`
   */
  inputRightClickable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  inputSx?: SystemStyleObject;
}

/**
 * @package
 */
const BaseFormInput: React.FC<BaseFormInputProps> = forwardRef<
  HTMLInputElement,
  BaseFormInputProps
>((props: BaseFormInputProps, ref) => {
  const {
    name,
    label,
    placeholder,
    help,
    type,
    inputLeft,
    inputLeftClickable,
    inputRight,
    inputRightClickable,
    error,
    isLoading,
    isLabelInline,
    inputSx,
    ...subset
  } = props;

  // TODO: GKS-2176 Chakra handles this internally now
  const id = useId();
  const fieldId = `form-input-${name}-${id}`;
  // ReadOnly styles should take precedence over disabled
  const isDisabled = subset.isDisabled && !subset.isReadOnly;
  const isReadOnly = subset.isReadOnly || isLoading;
  const finalIsDisabled = isDisabled || isReadOnly;
  const { formControlProps, rest } = formForwardProps({
    ...subset,
    isDisabled,
    isReadOnly,
  });

  return (
    <FormControl
      {...formControlProps}
      isInvalid={!!error}
      sx={{
        ...(formControlProps.sx as CSSObject),
        /**
         * For inline label
         * FIXME: Only works for type=date and Chrome
         * @see https://stackoverflow.com/a/37592923
         */
        ...(isLabelInline
          ? {
              'input:before': {
                color: 'gray.700',
                content: 'attr(data-label) !important',
                mr: 1,
              },
            }
          : null),
      }}
      display={type === 'hidden' ? 'none' : undefined}
      visibility={type === 'hidden' ? 'hidden' : undefined}
    >
      {label && !isLabelInline ? (
        <FormLabel htmlFor={fieldId}>{label}</FormLabel>
      ) : null}
      <InputGroup>
        <Input
          id={fieldId}
          ref={ref}
          name={name}
          type={type || 'text'}
          placeholder={placeholder ?? (isLabelInline ? label : undefined)}
          data-label={label}
          tabIndex={finalIsDisabled ? -1 : undefined}
          sx={{ ...(inputSx as CSSObject) }}
          {...rest}
        />
        {inputLeft ? (
          <InputLeftElement
            h="100%"
            w="fit-content"
            sx={{
              '> *': {
                ml: 2,
                mr: 1.5,
              },
            }}
            pointerEvents={!inputLeftClickable ? 'none' : undefined}
          >
            {inputLeft}
          </InputLeftElement>
        ) : null}
        {inputRight ? (
          <InputRightElement
            h="100%"
            w="fit-content"
            sx={{
              '> *': {
                ml: 1.5,
                mr: 2,
              },
            }}
            pointerEvents={!inputRightClickable ? 'none' : undefined}
          >
            {inputRight}
          </InputRightElement>
        ) : null}
      </InputGroup>
      {/* hide help text if error msg is shown */}
      {!help || error?.message ? null : <FormHelperText>{help}</FormHelperText>}
      <FormErrorMessage>
        <FormErrorIcon />
        {error?.message}
      </FormErrorMessage>
    </FormControl>
  );
});

const MemoizedInput = React.memo(BaseFormInput) as typeof BaseFormInput;

export { MemoizedInput as BaseFormInput };
