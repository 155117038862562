import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  useAccounts,
  clearCookies,
  NextPageCustom,
  pushGtmTrackEvent,
  Pages,
} from '@pepper/client';
import {
  withUnauthenticatedPageProps,
  UnauthenticatedPageProps,
} from '@pepper/client/ssr';
import { logger } from '@pepper/logger';
import { AlertMessage, FormInput } from '@deliveryhero/gfs-ui';
import {
  Box,
  Button,
  Center,
  Divider,
  Image,
  Text,
  Link,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { redirectTo, serverSideRedirect } from '~/utils/redirectTo';

interface IFormData {
  email: string;
  password: string;
}

const Login: NextPageCustom<UnauthenticatedPageProps> = ({ user }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { accountsClientPassword } = useAccounts();
  const [passwordLogin, setPasswordLogin] = useState(false);
  const { redirect, path, err } = router.query;

  const [result, setResult] = useState<{
    status: 'ERROR' | 'NONE';
    err?: string;
  }>({
    status: 'NONE',
  });

  useEffect(() => {
    if (user) {
      pushGtmTrackEvent('login.redirected', {
        label: 'Already logged in',
      });
      redirectTo(redirect as string, path as string);
    }
  }, [path, redirect, user]);

  async function onSubmit(values: IFormData): Promise<void> {
    const { email, password } = values;
    if (!email || !password) {
      setResult({
        status: 'ERROR',
        err: t('login.email_password_required'),
      });
      pushGtmTrackEvent('login.errored', {
        label: 'Email/Password required',
      });
      return;
    }
    try {
      // Delete existing cookies before login
      clearCookies('new-login-session');
      await accountsClientPassword.login({
        password,
        user: { email },
      });
      pushGtmTrackEvent('login.succeeded');
      redirectTo(redirect as string, path as string);
    } catch (error) {
      pushGtmTrackEvent('login.errored', {
        error,
      });
      setResult({
        status: 'ERROR',
        err: (error as Error).message,
      });
      logger.error({ err: error }, 'Login Errored');
    }
  }

  useEffect(() => {
    const { error: queryError } = router.query;
    if (queryError) {
      setResult({
        status: 'ERROR',
        err: queryError.toString(),
      });
      pushGtmTrackEvent('login.errored', {
        label: queryError.toString(),
      });
      logger.error({ err: queryError }, 'Login Errored at router');
    }
  }, [router.query]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IFormData>();

  return (
    <>
      <Text align="center" fontSize="xl" data-testid="title">
        {t('admin.welcome')}
      </Text>
      <Center display="flex">
        <Image
          width="200px"
          src="/shared/logos/pepper-text.png"
          alt="Pepper Logo"
        />
      </Center>
      {err ? (
        <AlertMessage mt={2} status="error" justifyContent="center">
          {err}
        </AlertMessage>
      ) : null}
      <Button
        p={3}
        width="100%"
        justifyContent="center"
        onClick={() => {
          pushGtmTrackEvent('login.clicked', {
            label: 'Login with Okta',
          });
          // We want to trigger a full reload so Cloudflare Access can set cookie
          window.location.assign(
            `/login-sso?redirect=${redirect ?? ''}&path=${path ?? ''}`,
          );
        }}
      >
        {t('login.login_with_okta')}
      </Button>
      <Divider />
      <Box>{t('login.or')}</Box>
      <Stack
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
        name="login-form"
        hidden={!passwordLogin}
      >
        {result.status === 'ERROR' ? (
          <AlertMessage mt={2} status="error">
            {result.err}
          </AlertMessage>
        ) : null}
        <FormInput
          name="email"
          label={t('login.email')}
          type="email"
          autoComplete="email"
          errors={errors}
          register={register}
          options={{
            required: 'required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('login.email_required_error'),
            },
          }}
        />
        <FormInput
          name="password"
          label={t('login.password')}
          type="password"
          autoComplete="current-password"
          errors={errors}
          register={register}
          options={{
            required: 'required',
            minLength: {
              value: 5,
              message: t('login.password_length_error'),
            },
          }}
        />
        <Box>
          <Link
            href={Pages.Auth.FORGOT}
            as={NextLink}
            pt={2}
            fontSize="sm"
            onClick={() =>
              pushGtmTrackEvent('login.clicked', {
                label: 'Forgot Password',
              })
            }
          >
            {t('forgot.forgot_password_title')}?
          </Link>

          <Box>
            <Button
              mt={4}
              width="100%"
              colorScheme="black"
              variant="outline"
              isLoading={isSubmitting}
              type="submit"
            >
              {t('login.title')}
            </Button>
          </Box>
        </Box>
      </Stack>
      <Button
        hidden={passwordLogin}
        width="100%"
        colorScheme="black"
        variant="outline"
        name="login-with-password"
        type="button"
        onClick={() => {
          pushGtmTrackEvent('login.clicked', {
            label: 'Login with password',
          });
          setPasswordLogin(true);
        }}
      >
        {t('login.title')}
      </Button>
    </>
  );
};

Login.config = (_props, t) => {
  return {
    title: t('login.title'),
    gtm: {
      pageTitle: 'Login Page',
    },
  };
};

export const getServerSideProps =
  withUnauthenticatedPageProps(serverSideRedirect);

export default Login;
